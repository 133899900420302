<template>
  <div>
    <img style="width: 44vw;margin-left: 27.99vw;margin-top: 222px;" src="@/assets/image/applying/icon_processing.png">
    <p class="page_title">审核中！</p>
    <p class="page_desc">{{ status_text }}</p>

    <el-button class="login-button">知 道 了</el-button>
  </div>

</template>

<script>
export default {
  name: "applying",
  data() {
    return {
      status_text: "您的申请尚在审核中，请耐心等待~",
      draftId:"",
    }
  },
  created() {
    document.title = "审核中"
  },
  mounted() {
    this.status_text = localStorage.getItem("merchantDraftStatusMessage") ? localStorage.getItem("merchantDraftStatusMessage") : "您的申请尚在审核中，请耐心等待~";
    this.draftId = localStorage.getItem("draftId")
    // console.log(this.draftId)
    if (this.draftId.length > 0){
      let request_uri = '/frontend/merchant/getMerchantDraft'
      let params = {
        draftId: this.draftId,
      }

      let _this = this;
      Http.post(request_uri, params).then(result => {
        if (result.code === 200) {
            let merchantDraftStatus = result.data.merchantDraftStatus?result.data.merchantDraftStatus:"2";
            this.status_text = result.data.statusDesc?result.data.statusDesc:"";

            // 更新本地状态值
            localStorage.setItem("merchantDraftStatus",merchantDraftStatus);
            localStorage.setItem("merchantDraftStatusMessage",this.status_text);
        }

      }).catch(error => {
        // console.log(error)
        // _this.$message.error('请求失败')

      }).finally(() => {
        _this.fullscreenLoading = false
      })
    }else{
      this.$router.push({path:"/h5/login"})
    }
  },
  methods: {}
}
</script>

<style scoped>
.page_title {
  width: 30vw;
  margin-left: 36vw;
  margin-top: 36px;
  text-align: center;
  height: 23px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 15px;
}

.page_desc {
  width: 80vw;
  margin-left: 10vw;
  margin-top: 36px;
  text-align: center;
  height: 15px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 15px;
  opacity: 0.6;
}

.login-button {
  width: 34.6vw;
  margin-left: 32.0vw;
  height: 44px;
  background: #F1BD20;
  color: white;
  box-shadow: 0px 6px 27px 0px rgba(241, 189, 32, 0.27);
  border-radius: 5.8vw;
  font-size: 18px;
  margin-top: 120px;
}
</style>